import {Reducer} from 'components/Layout/Reducer';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import styles from './index.module.scss';

type Props = {
  onClose: () => void;
  promocode: string;
};

export function PromocodeTopBanner({onClose, promocode}: Props): React.ReactElement {
  return (
    <div className={styles.layout}>
      <Reducer>
        <div className={styles.wrap}>
          <div className={styles.content}>
            <div className={styles.message}>
              <FormattedMessage
                defaultMessage='{promocode} promo code applied. Leave a request and we will contact you.'
                description='Promo code banner. Message'
                values={{
                  promocode: <span className={styles.promocode}>{promocode}</span>,
                }}
              />
            </div>
          </div>
          <button className={styles.closeButton} onClick={onClose} type='button' />
        </div>
      </Reducer>
    </div>
  );
}
