import {ExtMessageDescriptor} from 'lib/intl/types';

export enum NavigationId {
  ABOUT = 'about',
  INTERPRENEUR = 'interpreneur',
  PAYMENT = 'payment',
  PRODUCTS = 'products',
  QUALITY = 'quality',
  RADAR = 'radar',
  // legacy
  REFERRAL = 'referral',
  REVIEWS = 'reviews',
  SERVICE = 'service',
  SOLUTIONS = 'solutions',
}

type BaseNavigationItem = {
  message: ExtMessageDescriptor;
  prefix?: React.ReactNode;
};

export type LinkNavigationItem = BaseNavigationItem & {hash?: string; link: string; target?: '_blank'};
export type HashNavigationItem = BaseNavigationItem & {hash: string};
export type MenuNavigationItem = BaseNavigationItem & {items: (LinkNavigationItem | HashNavigationItem)[]};

export type NavigationItem = LinkNavigationItem | HashNavigationItem | MenuNavigationItem;
