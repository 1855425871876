import classNameBind from 'classnames/bind';
import {PromocodeTopBanner} from 'containers/PromocodeTopBanner';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import styles from './index.module.scss';

const cn = classNameBind.bind(styles);

type NavigationPosition = 'sticky' | 'static';

export type Props = TestIdProp<unknown> & {
  children: React.ReactNode;
  footer: React.ReactNode;
  navigation: React.ReactNode;
  navigationPosition?: NavigationPosition;
};

// Unify layout for all landing pages
// TODO: PRO-3599
export function MainLayout({
  children,
  navigation,
  navigationPosition = 'sticky',
  footer,
  testId,
}: Props): React.ReactElement {
  const isNavigationSticky = navigationPosition === 'sticky';
  const navigationClassName = cn(styles.navigation, `navigationPosition-${navigationPosition}`);
  const navigationContent = (
    <>
      <PromocodeTopBanner />
      {navigation}
    </>
  );

  return (
    <div className={styles.container} data-test-id={testId}>
      {isNavigationSticky && <div className={styles.navigationHidden}>{navigationContent}</div>}
      <div className={styles.content}>{children}</div>
      <div className={styles.footer}>{footer}</div>
      <div className={navigationClassName}>{navigationContent}</div>
    </div>
  );
}
