import {Reducer} from 'components/Layout/Reducer';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import styles from './index.module.scss';

export {DEFAULT_RU_TAGS} from './constants';

export type SeoTag = {
  label: string;
  onClick?: () => void;
};

type Props = {
  tags: SeoTag[];
};

export function SectionSeo({tags}: Props): React.ReactElement {
  return (
    <div className={styles.container}>
      <Reducer>
        <p className={styles.title}>
          <FormattedMessage
            defaultMessage='The following keywords are used when searching for us'
            description='Home page. Title for SEO text.'
          />
        </p>
        <ul className={styles.tags}>
          {tags.map(({label, onClick}) => (
            <li aria-hidden className={styles.tag} key={label} onClick={onClick} role={onClick ? 'button' : undefined}>
              {label}
            </li>
          ))}
        </ul>
      </Reducer>
    </div>
  );
}
