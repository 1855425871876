import {CommunicationOpenDialogParams, CommunicationReason} from 'lib/communication/types';
import React, {useMemo} from 'react';
import {MainContext, MainContextValue, MainContextProfileValue} from './contexts';
import {bindReason} from './utils';

export type UseMainContextValuesProps = {
  onOpenConsultationDialog?(data: CommunicationOpenDialogParams): void;
  onOpenContactUsDialog?(): void;
  onOpenLoyaltyConsultationDialog?(data: CommunicationOpenDialogParams): void;
  onOpenPartnersConsultationDialog?(data: CommunicationOpenDialogParams): void;
  onOpenPaymentsConsultationDialog?(data: CommunicationOpenDialogParams): void;
  onOpenPaymentsPartnersDialog?(data: CommunicationOpenDialogParams): void;
  onOpenReferralDialog?(data: CommunicationOpenDialogParams): void;
  profile?: MainContextProfileValue;
};

export type MainDecoratorProps = UseMainContextValuesProps & {
  children: React.ReactNode;
};

export const useMainContextValues = ({
  profile,
  onOpenContactUsDialog,
  onOpenReferralDialog,
  onOpenConsultationDialog,
  onOpenPaymentsPartnersDialog,
  onOpenPaymentsConsultationDialog,
  onOpenPartnersConsultationDialog,
  onOpenLoyaltyConsultationDialog,
}: UseMainContextValuesProps = {}): MainContextValue => {
  return useMemo<MainContextValue>(
    () => ({
      onOpenConsultationDialog:
        onOpenConsultationDialog && bindReason(onOpenConsultationDialog, CommunicationReason.CONSULTATION_CLAIM),
      onOpenContactUsDialog,
      onOpenLoyaltyConsultationDialog:
        onOpenLoyaltyConsultationDialog &&
        bindReason(onOpenLoyaltyConsultationDialog, CommunicationReason.LOYALTY_PROGRAM),
      onOpenPartnersConsultationDialog:
        onOpenPartnersConsultationDialog &&
        bindReason(onOpenPartnersConsultationDialog, CommunicationReason.PARTNERS_CONSULTATION),
      onOpenPaymentsConsultationDialog:
        onOpenPaymentsConsultationDialog &&
        bindReason(onOpenPaymentsConsultationDialog, CommunicationReason.PAYMENTS_CONSULTATION),
      onOpenPaymentsPartnersDialog:
        onOpenPaymentsPartnersDialog && bindReason(onOpenPaymentsPartnersDialog, CommunicationReason.PAYMENTS_PARTNERS),
      onOpenReferralDialog:
        onOpenReferralDialog && bindReason(onOpenReferralDialog, CommunicationReason.REFERRAL_PROGRAM),
      profile,
    }),
    [
      profile,
      onOpenContactUsDialog,
      onOpenReferralDialog,
      onOpenConsultationDialog,
      onOpenPaymentsPartnersDialog,
      onOpenPaymentsConsultationDialog,
      onOpenPartnersConsultationDialog,
      onOpenLoyaltyConsultationDialog,
    ],
  );
};

export function MainDecorator({children, ...rest}: MainDecoratorProps): React.ReactElement {
  const mainContextValue = useMainContextValues({...rest});

  return <MainContext.Provider value={mainContextValue}>{children}</MainContext.Provider>;
}
