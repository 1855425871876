import {CommunicationOpenDialogParams} from 'lib/communication/types';
import {User} from 'lib/user/types';
import {createContext} from 'react';

export type MainContextOpenDialogParams = Partial<CommunicationOpenDialogParams>;
export type MainContextOpenDialogHandler = (data?: MainContextOpenDialogParams) => void;

export type MainContextProfileValue = {
  onSignIn(): void;
  onSignOut(): void;
  user: User;
};

export type MainContextValue = {
  onOpenConsultationDialog?: MainContextOpenDialogHandler;
  onOpenContactUsDialog?: MainContextOpenDialogHandler;
  onOpenLoyaltyConsultationDialog?: MainContextOpenDialogHandler;
  onOpenPartnersConsultationDialog?: MainContextOpenDialogHandler;
  onOpenPaymentsConsultationDialog?: MainContextOpenDialogHandler;
  onOpenPaymentsPartnersDialog?: MainContextOpenDialogHandler;
  onOpenReferralDialog?: MainContextOpenDialogHandler;
  profile?: MainContextProfileValue;
  serverDate?: string;
};

export const MainContext = createContext<MainContextValue>({} as MainContextValue);
