import {defineMessages} from 'react-intl';
import {NavigationId} from './types';

export const menuMessages = defineMessages<NavigationId>({
  [NavigationId.REFERRAL]: {
    defaultMessage: 'Referral program',
    description: 'Home page. Navigation: Menu Item',
  },
  [NavigationId.INTERPRENEUR]: {
    defaultMessage: 'Our clients',
    description: 'Home page. Navigation: Menu Item',
  },
  [NavigationId.ABOUT]: {
    defaultMessage: 'About JoomPro',
    description: 'Home page. Navigation: Menu Item',
  },
  [NavigationId.PAYMENT]: {
    defaultMessage: 'Financial terms and conditions',
    description: 'Home page. Navigation: Menu Item',
  },
  [NavigationId.PRODUCTS]: {
    defaultMessage: 'Products',
    description: 'Home page. Navigation: Menu Item',
  },
  [NavigationId.RADAR]: {
    defaultMessage: 'Radar Siscomex',
    description: 'Home page. Navigation: Menu Item',
  },
  [NavigationId.REVIEWS]: {
    defaultMessage: 'Reviews',
    description: 'Home page. Navigation: Menu Item',
  },
  [NavigationId.SERVICE]: {
    defaultMessage: 'Service',
    description: 'Home page. Navigation: Menu Item',
  },
  [NavigationId.SOLUTIONS]: {
    defaultMessage: 'Our solutions',
    description: 'Home page. Navigation: Menu Item',
  },
  [NavigationId.QUALITY]: {
    defaultMessage: 'Quality',
    description: 'Home page. Navigation: Menu Item',
  },
});
