// эти фразы вставлены напрямую без использования react-intl
// потому что сейчас вся seo-секция показывается только для русского языка
// если она нужна будет для других языков, то там нужно будет подготовить свои seo-фразы, а не просто перевод в Crowdin
export const DEFAULT_RU_TAGS = [
  {label: 'оптом из Китая'},
  {label: 'доставка из Китая'},
  {label: 'товары из Китая оптом'},
  {label: 'поставки из Китая'},
  {label: 'производство в Китае'},
  {label: 'оптом от производителя'},
  {label: 'joom pro'},
  {label: 'joompro'},
  {label: 'joom оптом'},
  {label: 'джум про'},
  {label: 'джумпро'},
];
