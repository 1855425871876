import {menuMessages} from 'components/Main/lib/messages';
import {NavigationId} from 'components/Main/lib/types';
import {NavItem} from 'components/Main/Navigation';

export function getNavItems(navItemIds: NavigationId[]): NavItem[] {
  return navItemIds.map((id) => ({
    hash: id,
    id,
    message: menuMessages[id],
  }));
}
