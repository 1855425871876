import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import {useCallbackWithoutArgs} from 'lib/hooks';
import {TestIdProp} from 'lib/testing/types';
import {User} from 'lib/user/types';
import React, {useCallback, useRef, useState} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {Button} from 'uikit/Button';
import {Menu, MenuItem} from 'uikit/Menu';
import styles from './index.module.scss';
import ProfileIcon from './profile.svg';

const messages = defineMessages({
  orders: {
    defaultMessage: 'My orders',
    description: 'Page header: Orders menu item',
  },
  profile: {
    defaultMessage: 'Profile',
    description: 'Page header: Profile button',
  },
  signIn: {
    defaultMessage: 'Sign in',
    description: 'Page header: Login button',
  },
  signOut: {
    defaultMessage: 'Sign out',
    description: 'Page header: Exit menu item',
  },
});

export type ProfileTestId = {
  button: unknown;
  menu: {
    myOrdersButton: unknown;
    signOutButton: unknown;
  };
};

export type Profile = {
  onSignIn: () => void;
  onSignOut: () => void;
  user: User;
};

export type ProfileProps = TestIdProp<ProfileTestId> & {
  inverse?: boolean;
} & Profile;

export function ProfileButton({onSignIn, onSignOut, inverse, testId, user}: ProfileProps): React.ReactElement {
  const appEnv = useAppEnv();
  const analytics = useAnalytics();
  const rootRef = useRef<HTMLDivElement>(null);
  const [menuVisible, setMenuVisible] = useState(false);

  const handleClick = useCallback(() => {
    if (user.anonymous) {
      onSignIn();
    } else {
      setMenuVisible(true);
    }

    analytics.sendEvent({
      payload: {
        pageUrl: window.location.href,
        source: appEnv.currentPageName,
      },
      type: 'userProfileClick',
    });
  }, [onSignIn, user, appEnv, analytics]);

  const handleMenuClose = useCallback(() => setMenuVisible(false), []);
  const handleSignOut = useCallbackWithoutArgs(onSignOut);

  return (
    <div data-test-id={testId} ref={rootRef}>
      <div className={styles.desktop}>
        <Button
          color={inverse ? 'outline-white' : 'outline-black'}
          iconLeft={<ProfileIcon />}
          onClick={handleClick}
          shape='round'
          type='button'
        >
          {user.anonymous ? <FormattedMessage {...messages.signIn} /> : <FormattedMessage {...messages.profile} />}
        </Button>
      </div>
      <div className={styles.mobile}>
        <Button
          color={inverse ? 'outline-white' : 'outline-black'}
          iconLeft={<ProfileIcon />}
          onClick={handleClick}
          shape='circle'
          type='button'
        />
      </div>
      {user && menuVisible && (
        <Menu onClose={handleMenuClose} targetRef={rootRef} testId={testId?.menu}>
          <MenuItem onClick={handleSignOut} testId={testId?.menu.signOutButton}>
            <FormattedMessage {...messages.signOut} />
          </MenuItem>
        </Menu>
      )}
    </div>
  );
}
